/*==================== REUSABLE CSS CLASSES ====================*/
.section {
  padding: 2rem 0 4rem;
}

.section__title {
  font-size: var(--h1-font-size);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
  max-width: 768px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/*==================== BUTTONS ====================*/
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
  cursor: pointer;
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button__icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: var(--mb-0-25);
  transition: 0.3s;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

.button--small {
  padding-top: 0.75rem 1rem;
}

.button--link {
  padding: 0;
  background-color: transparent;
  color: var(--first-color);
}

.button--link:hover {
  background-color: transparent;
  color: var(--first-color-alt);
}

button {
  outline: none;
  border: none;
}

@media screen and (max-width:350px){
    .container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  body {
    margin: 0;
  }

  .section {
    padding: 6rem 0 2rem;
  }

  .section__subtitle {
    margin-bottom: 4rem;
  }

  .main {
    padding: 0 1rem;
  }
}
