.contact__container {
  row-gap: 3rem;
}

.contact__information {
  display: flex;
  margin-bottom: var(--mb-2);
}

.contact__icon {
  font-size: 2rem;
  color: var(--first-color);
  margin-right: var(--mb-0-75);
}

.contact__title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.contact__subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.contact__content {
  background-color: var(--input-color);
  border-radius: 0.3rem;
  padding: 0.75rem 1rem 0.25rem;
}

.contact__label {
  font-size: var(--small-font-size);
  color: var(--title-color);
}

.contact__input {
  width: 100%;
  background-color: var(--input-color);
  color: var(--text-color-light);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem 0.5rem 0;
}

.error {
  border: 1px solid red;
}

.contact__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  /* opacity: 0;
      visibility: hidden; */
  transition: 0.3s;
}

.contact__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 40%;
}

.contact__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__modal-icon {
  width: 3rem;
  height: 3rem;
  margin-right: 0.8rem;
  color: rgb(80, 228, 80);
}

.contact__modal-name {
  margin-top: 0.3rem;
}

.contact__modal-button {
  display: flex;
  justify-content: center;
}

.contact__modal-show {
  opacity: 0;
  visibility: hidden;
}

@keyframes ldio-vtjaoojajnm {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-vtjaoojajnm div { box-sizing: border-box!important }
.ldio-vtjaoojajnm > div {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2px;
  left: 5px;
  border-radius: 50%;
  border: 2px solid #000;
  border-color: white transparent white transparent;
  animation: ldio-vtjaoojajnm 1s linear infinite;
}
.ldio-vtjaoojajnm > div:nth-child(2) { border-color: transparent }
.ldio-vtjaoojajnm > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-vtjaoojajnm > div:nth-child(2) div:before, .ldio-vtjaoojajnm > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: 8px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 20px 0 0 white;
}
.ldio-vtjaoojajnm > div:nth-child(2) div:after { 
  left: -2px;
  top: 8px;
  box-shadow: 20px 0 0 0 white;
}
.loadingio-spinner-dual-ring-y76a60ogklm {
  width: 23px;
  height: 23px;
  display: inline-block;
  overflow: hidden;

}
.ldio-vtjaoojajnm {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-vtjaoojajnm div { box-sizing: content-box; }


@media screen and (min-width: 568px) {
  .contact__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .contact__modal-content {
    
    width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .contact__form {
    width: 460px;
  }
  .contact__inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}
