.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.logo-img {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 30px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/*==================== NAV ====================*/
.nav {
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;   
}

.nav__logo {
  display: flex;
  align-items: center;
}

.nav__logo h2 {
  font-family: "Lobster", cursive;
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  font-weight: 800;
  color: #092c3e;
  text-shadow: 0px 0px 2px #fff;
  cursor: pointer;
  user-select: none;
}
.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__logo:hover{
  color: var(--first-color);
}

.nav__toggle {
  width: 1.1rem;
  cursor: pointer;
}

.nav__toggle:hover{
  color: var(--first-color)
}

@media screen and (max-width: 767px) {
  .nav__menu {
      position: fixed;
      /* bottom: -100%; */
      left: 0;
      width: 100%;
      background-color: var(--body-color);
      padding: 2rem 1.5rem 4rem;
      box-shadow: 0 -1px 4px rgba(0,0,0,.15);
      border-radius: 1.5rem 1.5rem 0 0;
      transition: .3s;
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.nav__link:hover{
  color: var(--first-color);
}

.nav__icon {
  width: 1.2rem;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: .5rem;
  width: 1.5rem;
  cursor: pointer;
  color: var(--first-color);
}

.nav__close:hover{
  color: var(--first-color-alt);
}
/* show menu */
.show-nav {
  bottom: 0;
}

.hide-nav {
  bottom: -100%;
}

/*========== Button Dark/Light ==========*/
.nav__btns {
  display: flex;
  align-items: center;
}

.change-theme {
  width: 1.25rem;
  color: var(--title-color);
  margin-right: var(--mb-1);
  cursor: pointer;
}

.change-theme:hover{
  color: var(--first-color-lighter);
}

@media screen and (max-width:350px){
  .nav__menu {
      padding: 2rem .25rem 4rem;
  }

  .nav__list {
      column-gap: 0;
  }
}

@media screen and (min-width: 768px){
  .header {
      top: 0;
      bottom: initial;
  }

  .header {
      padding: 0 1rem;
  }

  .nav {
      height: calc(var(--header-height) + 1.5rem);
      column-gap: 1rem;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
      display: none;
  }

  .nav__list {
      display: flex;
      column-gap: 2rem;
  }

  .nav__menu {
      margin-left: auto;
  }
}

@media screen and (min-width: 1024px){
  .header{
      padding: 0;
  }
}