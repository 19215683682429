.about__img {
    width: 200px;
    border-radius: .5rem;
    margin: 0 0 auto;
    align-self: center;
    justify-self: center;
}

.about__description {
    text-align: center;
    margin-bottom: var(--mb-2-5);
}

.about__info {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: var(--mb-2-5);
}

.about__info-title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    color: var(--title-color);
}

.about__info-name {
    font-size: var(--smaller-font-size);
}

.about__info-title,
.about__info-name {
    display: block;
    text-align: center;
}

.about__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (min-width: 568px){
    .about__container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 768px){
    .about__container {
        column-gap: 5rem;
    }

    .about__img {
        width: 350px;
    }

    .about__description {
        text-align: initial;
    }

    .about__info {
        justify-content: space-between;
    }

    .about__buttons {
        justify-content: initial;
    }

}