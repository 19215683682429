.project__container {
    overflow: initial;
}

.project__content {
    padding: 0 1.5rem;
}

.project__img {
    width: 256px;
    border-radius: .5rem;
    justify-self: center;
}

.project__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-0-5);
}

.project__description {
    margin-bottom: var(--mb-0-75);
}

.project__button:hover .button__icon {
    transform: translateX(.25rem);
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 1.5rem !important;
    color: var(--first-color) !important;
    font-weight: bolder !important;
}

.swiper-project-icon {
    font-size: 2rem;
    color: var(--first-color);
}

.swiper-button-prev::after {
    left: -.5rem !important;
}

.swiper-button-next::after {
    right: -.5rem !important;
}

.swiper-container {
    overflow: inherit !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -2.5rem !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--first-color) !important;
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullet {
    outline: none !important;
}

@media screen and (min-width: 568px){
    .project__content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 768px){
    .project__img {
        width: 320px;
    }

    .project__content {
        align-items: center;
    }
}

@media screen and (min-width: 1024px){
    .project__content {
        column-gap: 5rem;
    }

    .swiper-project-icon {
        font-size: 3.5rem;
    }

    .swiper-button-prev {
        left: -3.5rem !important;
    }

    .swiper-button-next {
        right: -3.5rem !important;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: -4.5rem;
    }
}