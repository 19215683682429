@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
/*==================== REUSABLE CSS CLASSES ====================*/
.section {
  padding: 2rem 0 4rem;
}

.section__title {
  font-size: var(--h1-font-size);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
  max-width: 768px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

/*==================== BUTTONS ====================*/
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
  cursor: pointer;
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button__icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: var(--mb-0-25);
  transition: 0.3s;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

.button--small {
  padding-top: 0.75rem 1rem;
}

.button--link {
  padding: 0;
  background-color: transparent;
  color: var(--first-color);
}

.button--link:hover {
  background-color: transparent;
  color: var(--first-color-alt);
}

button {
  outline: none;
  border: none;
}

@media screen and (max-width:350px){
    .container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  body {
    margin: 0;
  }

  .section {
    padding: 6rem 0 2rem;
  }

  .section__subtitle {
    margin-bottom: 4rem;
  }

  .main {
    padding: 0 1rem;
  }
}

.about__img {
    width: 200px;
    border-radius: .5rem;
    margin: 0 0 auto;
    align-self: center;
    justify-self: center;
}

.about__description {
    text-align: center;
    margin-bottom: var(--mb-2-5);
}

.about__info {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: var(--mb-2-5);
}

.about__info-title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    color: var(--title-color);
}

.about__info-name {
    font-size: var(--smaller-font-size);
}

.about__info-title,
.about__info-name {
    display: block;
    text-align: center;
}

.about__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (min-width: 568px){
    .about__container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 768px){
    .about__container {
        grid-column-gap: 5rem;
        -webkit-column-gap: 5rem;
                column-gap: 5rem;
    }

    .about__img {
        width: 350px;
    }

    .about__description {
        text-align: initial;
    }

    .about__info {
        justify-content: space-between;
    }

    .about__buttons {
        justify-content: initial;
    }

}
.contact__container {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.contact__information {
  display: flex;
  margin-bottom: var(--mb-2);
}

.contact__icon {
  font-size: 2rem;
  color: var(--first-color);
  margin-right: var(--mb-0-75);
}

.contact__title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.contact__subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.contact__content {
  background-color: var(--input-color);
  border-radius: 0.3rem;
  padding: 0.75rem 1rem 0.25rem;
}

.contact__label {
  font-size: var(--small-font-size);
  color: var(--title-color);
}

.contact__input {
  width: 100%;
  background-color: var(--input-color);
  color: var(--text-color-light);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem 0.5rem 0;
}

.error {
  border: 1px solid red;
}

.contact__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  /* opacity: 0;
      visibility: hidden; */
  transition: 0.3s;
}

.contact__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 40%;
}

.contact__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__modal-icon {
  width: 3rem;
  height: 3rem;
  margin-right: 0.8rem;
  color: rgb(80, 228, 80);
}

.contact__modal-name {
  margin-top: 0.3rem;
}

.contact__modal-button {
  display: flex;
  justify-content: center;
}

.contact__modal-show {
  opacity: 0;
  visibility: hidden;
}

@-webkit-keyframes ldio-vtjaoojajnm {
  0% { -webkit-transform: rotate(0); transform: rotate(0) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

@keyframes ldio-vtjaoojajnm {
  0% { -webkit-transform: rotate(0); transform: rotate(0) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}
.ldio-vtjaoojajnm div { box-sizing: border-box!important }
.ldio-vtjaoojajnm > div {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2px;
  left: 5px;
  border-radius: 50%;
  border: 2px solid #000;
  border-color: white transparent white transparent;
  -webkit-animation: ldio-vtjaoojajnm 1s linear infinite;
          animation: ldio-vtjaoojajnm 1s linear infinite;
}
.ldio-vtjaoojajnm > div:nth-child(2) { border-color: transparent }
.ldio-vtjaoojajnm > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ldio-vtjaoojajnm > div:nth-child(2) div:before, .ldio-vtjaoojajnm > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: 8px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 20px 0 0 white;
}
.ldio-vtjaoojajnm > div:nth-child(2) div:after { 
  left: -2px;
  top: 8px;
  box-shadow: 20px 0 0 0 white;
}
.loadingio-spinner-dual-ring-y76a60ogklm {
  width: 23px;
  height: 23px;
  display: inline-block;
  overflow: hidden;

}
.ldio-vtjaoojajnm {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.ldio-vtjaoojajnm div { box-sizing: content-box; }


@media screen and (min-width: 568px) {
  .contact__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .contact__modal-content {
    
    width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .contact__form {
    width: 460px;
  }
  .contact__inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}

.footer {
    padding-top: 2rem;
}

.footer__container {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
}

.footer__bg {
    background-color: var(--first-color-second);
    padding: 2rem 0 3rem;
}

.footer__title {
    font-size: var(--h1-font-size);
    margin-bottom: var(--mb-0-25);
}

.footer__subtitle {
    font-size: var(--small-font-size);
}

.footer__links {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
}

.footer__link:hover{
    color: var(--first-color-lighter);
}

.footer__social {
    font-size: 1.25rem;
    margin-right: var(--mb-1-5);
}

.footer__social:hover{
    color: var(--first-color-lighter);
}

.footer__copy {
    font-size: var(--smaller-font-size);
    text-align: center;
    color: #fff;
    margin-top: var(--mb-3);
}

.footer__title,
.footer__subtitle,
.footer__link,
.footer__social{
    color: #fff;
}

@media screen and (min-width: 568px){
    .footer__container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 768px){
    .footer__container {
        padding: 0 1rem;
    }

    .footer__container {
        grid-template-columns: repeat(3, 1fr);
    }

    .footer__bg {
        padding: 3rem 0 3.5rem;
    }

    .footer__links {
        flex-direction: row;
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem;
    }

    .footer__socials {
        justify-self: flex-end;
    }

    .footer__copy {
        margin-top: 4.5rem;
    }
}

@media screen and (min-width: 1024px){
    .footer__container {
        padding: 0;
    }
}
.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.logo-img {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 30px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/*==================== NAV ====================*/
.nav {
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;   
}

.nav__logo {
  display: flex;
  align-items: center;
}

.nav__logo h2 {
  font-family: "Lobster", cursive;
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  font-weight: 800;
  color: #092c3e;
  text-shadow: 0px 0px 2px #fff;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__logo:hover{
  color: var(--first-color);
}

.nav__toggle {
  width: 1.1rem;
  cursor: pointer;
}

.nav__toggle:hover{
  color: var(--first-color)
}

@media screen and (max-width: 767px) {
  .nav__menu {
      position: fixed;
      /* bottom: -100%; */
      left: 0;
      width: 100%;
      background-color: var(--body-color);
      padding: 2rem 1.5rem 4rem;
      box-shadow: 0 -1px 4px rgba(0,0,0,.15);
      border-radius: 1.5rem 1.5rem 0 0;
      transition: .3s;
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.nav__link:hover{
  color: var(--first-color);
}

.nav__icon {
  width: 1.2rem;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: .5rem;
  width: 1.5rem;
  cursor: pointer;
  color: var(--first-color);
}

.nav__close:hover{
  color: var(--first-color-alt);
}
/* show menu */
.show-nav {
  bottom: 0;
}

.hide-nav {
  bottom: -100%;
}

/*========== Button Dark/Light ==========*/
.nav__btns {
  display: flex;
  align-items: center;
}

.change-theme {
  width: 1.25rem;
  color: var(--title-color);
  margin-right: var(--mb-1);
  cursor: pointer;
}

.change-theme:hover{
  color: var(--first-color-lighter);
}

@media screen and (max-width:350px){
  .nav__menu {
      padding: 2rem .25rem 4rem;
  }

  .nav__list {
      grid-column-gap: 0;
      -webkit-column-gap: 0;
              column-gap: 0;
  }
}

@media screen and (min-width: 768px){
  .header {
      top: 0;
      bottom: initial;
  }

  .header {
      padding: 0 1rem;
  }

  .nav {
      height: calc(var(--header-height) + 1.5rem);
      grid-column-gap: 1rem;
      -webkit-column-gap: 1rem;
              column-gap: 1rem;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
      display: none;
  }

  .nav__list {
      display: flex;
      grid-column-gap: 2rem;
      -webkit-column-gap: 2rem;
              column-gap: 2rem;
  }

  .nav__menu {
      margin-left: auto;
  }
}

@media screen and (min-width: 1024px){
  .header{
      padding: 0;
  }
}
.quote__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  transition: 0.3s;
}

.quote__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
  /* width: 40%; */
}

.quote__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote__modal-button {
  display: flex;
  justify-content: center;
}

.quote__modal-subtitle {
  text-align: right;
}

.quote__modal-quote {
  margin: 5px;
  margin-bottom: var(--mb-1-5);
}
.home__container {
    grid-gap: 1rem;
    gap: 1rem;
}

.home__content {
    grid-template-columns: .5fr 3fr;
    padding-top: 3.5rem;
    align-items: center;
}

.home__social {
    display: grid;
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.home__social-icon {
    width: 1.25rem;
    color: var(--first-color)
}

.home__social-icon:hover {
    color: var(--first-color-alt)
}

.home__blob {
    width: 200px;
    fill: var(--first-color)
}

.home__blob-img {
    width: 200px;
}

.home__data {
    grid-column: 1/3;
}

.home__title {
    font-size: var(--big-font-size);
}

.home__subtitle {
    font-size: var(--h3-font-size);
    color: var(--text-color);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-75);
}

.home__description {
    margin-bottom: var(--mb-2);
}

.home__scoll {
    /* display: none; */
}

.home__scroll-button {
    color: var(--first-color);
    transition: .3s;
}

.home__scroll-button:hover{
    -webkit-transform: translateY(.25rem);
            transform: translateY(.25rem);
}

.home__scroll-mouse {
    width: 2rem;
    height: 2rem;
}

.home__scroll-name {
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
}

.home__scroll-arrow {
    width: 1.25rem;
}

@media screen and (max-width:350px){
    .home__content {
        grid-template-columns: .25fr 3fr;
    }

    .home__blob {
        width: 180px;
    }

}

@media screen and (min-width: 568px){
    .home__content {
        grid-template-columns: -webkit-max-content 1fr 1fr;
        grid-template-columns: max-content 1fr 1fr;
    }

    .home__data {
        grid-column: initial;
    }

    .home__img {
        order: 1;
        justify-self: center;
    }
}

@media screen and (min-width: 768px){
    .home__container {
        grid-row-gap: 5rem;
        row-gap: 5rem;
    }

    .home__content {
        padding-top: 5rem;
        grid-column-gap: 2.2rem;
        -webkit-column-gap: 2.2rem;
                column-gap: 2.2rem;
    }

    .home__blob {
        width: 270px;
    }

    .home__scroll{
        display: block;
    }

    .home__scroll-button {
        margin-left: 3rem;
    }

}

@media screen and (min-width: 1024px){
    .home__blob {
        width: 320px;
    }

    .home__social {
        -webkit-transform: translateX(-6rem);
                transform: translateX(-6rem);
    }
}

.project__container {
    overflow: initial;
}

.project__content {
    padding: 0 1.5rem;
}

.project__img {
    width: 256px;
    border-radius: .5rem;
    justify-self: center;
}

.project__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-0-5);
}

.project__description {
    margin-bottom: var(--mb-0-75);
}

.project__button:hover .button__icon {
    -webkit-transform: translateX(.25rem);
            transform: translateX(.25rem);
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 1.5rem !important;
    color: var(--first-color) !important;
    font-weight: bolder !important;
}

.swiper-project-icon {
    font-size: 2rem;
    color: var(--first-color);
}

.swiper-button-prev::after {
    left: -.5rem !important;
}

.swiper-button-next::after {
    right: -.5rem !important;
}

.swiper-container {
    overflow: inherit !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -2.5rem !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--first-color) !important;
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullet {
    outline: none !important;
}

@media screen and (min-width: 568px){
    .project__content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 768px){
    .project__img {
        width: 320px;
    }

    .project__content {
        align-items: center;
    }
}

@media screen and (min-width: 1024px){
    .project__content {
        grid-column-gap: 5rem;
        -webkit-column-gap: 5rem;
                column-gap: 5rem;
    }

    .swiper-project-icon {
        font-size: 3.5rem;
    }

    .swiper-button-prev {
        left: -3.5rem !important;
    }

    .swiper-button-next {
        right: -3.5rem !important;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: -4.5rem;
    }
}
.services__container {
  grid-gap: 1.5rem;
  gap: 1.5rem;
  /* grid-template-rows: repeat(2, 1fr); */
}

.services__content {
  position: relative;
  background-color: var(--container-color);
  padding-top: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  padding: 15px;
}

.services__content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.services__icon {
  display: block;
  font-size: 1.5rem;
  color: var(--first-color);
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}

.services__button {
  cursor: pointer;
  font-size: var(--smaller-font-size);
}

.services__button:hover .button__icon {
  -webkit-transform: translateX(0.25rem);
          transform: translateX(0.25rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  /* opacity: 0;
    visibility: hidden; */
  transition: 0.3s;
}

.services__modal-show {
  opacity: 0;
  visibility: hidden;
}

.services__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.services__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.services__modal-services {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.services__modal-service {
  display: flex;
  align-items: center;
}

.services__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.services__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.services__modal-icon {
  color: var(--first-color);
  margin-right: var(--mb-0-25);
}

@media screen and (max-width: 350px) {
  .services__modal {
    padding: 0.5rem;
  }

  .services__content {
    padding: 5px;
}
}

@media screen and (min-width: 568px) {
  .services__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .services__content {
      padding: 10px;
  }
}

@media screen and (min-width: 768px) {
  .services__icon {
    font-size: 2rem;
  }

  .services__content {
    padding: 4rem 0 2rem 2.5rem;
  }

  .services__modal-content {
    width: 450px;
  }
}

.services__container {
  grid-gap: 1.5rem;
  gap: 1.5rem;
  /* grid-template-rows: repeat(2, 1fr); */
}

.services__content {
  position: relative;
  background-color: var(--container-color);
  padding-top: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  padding-left: 1rem;
}

.services__content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.services__icon {
  display: block;
  font-size: 1.5rem;
  color: var(--first-color);
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}

.services__button {
  cursor: pointer;
  font-size: var(--smaller-font-size);
}

.services__button:hover .button__icon {
  -webkit-transform: translateX(0.25rem);
          transform: translateX(0.25rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  /* opacity: 0;
    visibility: hidden; */
  transition: 0.3s;
}

.services__modal-show {
  opacity: 0;
  visibility: hidden;
}

.services__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.services__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.services__modal-services {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.services__modal-service {
  display: flex;
  align-items: center;
}

.services__modal-service p {
  font-size: .7rem;
}

.services__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.services__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.services__modal-icon {
  color: var(--first-color);
  margin-right: var(--mb-0-25);
}

@media screen and (max-width: 350px) {
  .services__modal {
    padding: 0.5rem;
  }

  .services__content {
    padding: 5px;
}
}

@media screen and (min-width: 568px) {
  .services__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .services__content {
      padding: 10px;
  }
}

@media screen and (min-width: 768px) {
  .services__icon {
    font-size: 2rem;
  }

  .services__content {
    padding: 4rem 0 2rem 2.5rem;
  }

  .services__modal-content {
    width: 450px;
  }
}

/*==================== VARIABLES CSS ====================*/
:root {
    --header-height: 3rem;

    /*========== Colors ==========*/
    /* Change favorite color */
    --hue-color: 216; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

    /* HSL color mode */
    --first-color: hsl(var(--hue-color), 69%, 61%);
    --first-color-second: hsl(var(--hue-color), 69%, 61%);
    --first-color-alt: hsl(var(--hue-color), 57%, 53%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --container-color: #fff;
 
    /*========== Font and typography ==========*/
    --body-font: 'Poppins', sans-serif;

    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semi-bold: 600;

    /*========== Margenes Bottom ==========*/
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    --mb-0-25: .25rem;
    --mb-0-5: .5rem;
    --mb-0-75: .75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
    :root {
        --big-font-size: 3rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

/*========== Variables Dark theme ==========*/
.theme-dark {
    --first-color-second: hsl(var(--hue-color), 30%, 8%);
    --title-color: hsl(var(--hue-color), 8%, 95%);
    --text-color: hsl(var(--hue-color), 8%, 75%);
    --input-color: hsl(var(--hue-color), 29%, 16%);
    --body-color: hsl(var(--hue-color), 28%, 12%);
    --container-color: hsl(var(--hue-color), 29%, 16%);

}

/*==================== BASE ====================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 3rem 0;
  margin: 0 0 var(--header-height) 0;
  font-family: 'Poppins', sans-serif;
  font-family: var(--body-font);
  font-size: .938rem;
  font-size: var(--normal-font-size);
  background-color: hsl(216, 60%, 99%);
  background-color: var(--body-color);
  color: hsl(216, 8%, 45%);
  color: var(--text-color)
}

h1,h2,h3,h4{
  color: hsl(216, 8%, 15%);
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}
