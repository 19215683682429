.quote__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  transition: 0.3s;
}

.quote__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
  /* width: 40%; */
}

.quote__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote__modal-button {
  display: flex;
  justify-content: center;
}

.quote__modal-subtitle {
  text-align: right;
}

.quote__modal-quote {
  margin: 5px;
  margin-bottom: var(--mb-1-5);
}